/* eslint-disable react/prop-types */

const FlightCard = ({
  img,
  duration,
  name,
  time,
  stop,
  trip,
  price,
  leaves,
  arrives,
  hnl,
  from,
}) => {
  return (
    <>
      <div className="w-full flex flex-row items-start justify-between gap-7 p-4 ">
        <div className="flex items-start gap-2">
          <img
            src={img}
            alt="hawaiian"
            className="w-6 h-6 sm:w-9 sm:h-9 object-contain"
          />
          <div className="flex flex-col items-start justify-start">
            <h2 className="text-[#27273F] font-normal text-xs  md:text-base">
              {from}
            </h2>
            <p className="text-[#7C8DB0] font-normal text-xs   md:text-base">
              {name}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center sm:items-end justify-start">
          <p className="hidden md:block text-[#27273F] font-normal text-xs   md:text-base">
            Leaves At - Arrives At
          </p>
          <p className="text-[#7C8DB0] font-normal text-xs  md:text-base">
            {leaves} - {arrives}
          </p>
        </div>
        <div className="flex flex-col  items-center sm:items-end justify-start">
          <p className="text-[#27273F] font-normal text-xs   md:text-base">
            Seats
          </p>
          <p className="text-[#7C8DB0] font-normal text-xs   md:text-base">
            {stop}
          </p>
        </div>
        <div className="flex flex-col items-center sm:items-end justify-start">
          <p className="text-[#27273F] font-normal text-xs  md:text-base">
            Kshs.{price}
          </p>

          <div className="md:block px-1 bg-blue-500 flex rounded-md justify-center items-center">
            <p className="text-white md:text-lg text-sm">Select Seat</p>
          </div>
        </div>
      </div>
      {/* <div className="md:hidden block">
        <p className="md:p-3 p-1 bg-blue-500 flex rounded-md justify-center items-center h-8 w-full">
          <p className="text-white md:text-lg text-sm">Select Seat</p>
        </p>
      </div> */}
    </>
  );
};

export default FlightCard;
