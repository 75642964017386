import { Pricehistory, shangai } from "../assets/images";

const PriceGraph = () => {
  return (
    <>
      <div className="flex flex-col gap-10 ">
        <div>
          <div className="flex flex-col items-start justify-start">
            
          </div>
          <div className="hidden md:block mt-5 w-full sm:w-[397px] h-[247px] flex flex-col border-[1px] border-[#E9E8FC] rounded-xl  ">
            <img src={shangai} className="w-full h-full rounded-2xl" alt="" />
          </div>
        </div>
        {/* <div className="hidden md:block flex flex-col items-start justify-start gap-5">
          <div className="flex flex-col items-start justify-start">
            <h1 className="text-[#6E7491] font-semibold text-lg leading-6">
              Price history
            </h1>
          </div>
          <div className="w-full h-full">
            <img
              src={Pricehistory}
              alt="Price-history"
              className="w-full h-full object-contain"
            />
          </div>
        </div> */}
        <div className="flex flex-col items-start justify-start gap-5">
          <div className="flex items-center justify-start gap-3">
            
            <button className="bg-[#5CD6C0] px-2 py-1 rounded text-white font-semibold">
              Book soon
            </button>
          </div>
          <div className="flex flex-col items-start justify-start max-w-sm gap-2 px-3">
            <p className="text-[#6E7491] ">
              Book with XeddoTravelink today to experince a comfy way of traveling.
            </p>
            <p className="text-[#A1B0CC] ">
              We provide the best services to ensure you are comfortable.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceGraph;
