import { Link, useLocation, useNavigate } from "react-router-dom";
import { tripma } from "../assets/logo";
import { MdOutlineClose } from "react-icons/md";
import { BiMenuAltLeft } from "react-icons/bi";
import { useEffect, useState } from "react";
import { Signin } from "../container";
import { mylogo, xeddo } from "../assets/images";
import { toast } from "react-toastify";

const Navbar = () => {
  const location = useLocation();
  const [toggle, setToggle] = useState(false);
  const [signin, setSignin] = useState(false);
  const [loginState, setLoginstate] = useState(false);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check out this app!",
          text: "I found this amazing app. You should try it too!",
          url: window.location.href, // Current page URL
        });
        console.log("Content shared successfully");
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  useEffect(() => {
    const checkLogin = async () => {
      const status = localStorage.getItem("Authenticated");
      if (status) {
        setLoginstate(true);
      }
    };
    checkLogin();
  }, [loginState]);

  const loactionPath = (route) => {
    if (route === location.pathname) {
      return true;
    }
  };
  const navigate = useNavigate();

  const Logout = async () => {
    localStorage.setItem("email", "");
    localStorage.setItem("quid", "");
    localStorage.setItem("token", "");
    localStorage.setItem("Authenticated", "");
    navigate("/login");
    toast.success("Logout successfully");
    window.location.reload();  // This will reload the page
};


  return (
    <>
      <nav className="w-full flex flex-row items-center justify-between md:px-5 py-4 relative bg-blue-800 mb-4">
        <div className="flex items-center justify-center gap-3">
          <Link to="/" className="flex space-x-3 space-between items-center">
            <img src={mylogo} className="h-10 w-10" alt="" />
            <div>
              <h3 className="font-semibold text-2xl text-white">
                Xeddo Travelink
              </h3>
              <div className="flex justify-between items-center">
                <p className="text-yellow-400 text-xs">expeditious</p>{" "}
                <div className="text-white">|</div>
                <p className="text-yellow-400 text-xs">stellar</p>{" "}
                <div className="text-white">|</div>
                <p className="text-yellow-400 text-xs">travelpal</p>
              </div>
            </div>
          </Link>
        </div>

        <div className="relative md:hidden flex items-center">
          {toggle ? (
            <MdOutlineClose
              alt="close"
              className="w-7 h-7 text-white cursor-pointer"
              onClick={() => setToggle(false)}
            />
          ) : (
            <BiMenuAltLeft
              className="w-7 h-7 text-white cursor-pointer"
              onClick={() => setToggle(true)}
            />
          )}
          {toggle && (
            <ul className="absolute w-64 z-10 h-fit bg-[#FFFFFF] shadow-xl top-14 right-0 text-[#7C8DB0] flex flex-col gap-2 items-start p-4 scaleUp">
              <Link
                to="/"
                className={`text-base  hover:text-black transition-all duration-200 ${
                  loactionPath("/") && "text-blue-500"
                }`}
              >
                <li>Book Now</li>
              </Link>
              {loginState && (
                <Link
                  to="/bookings"
                  className={`text-base  hover:text-black transition-all duration-200 ${
                    loactionPath("/") && "text-blue-500"
                  }`}
                >
                  <li>My Bookings</li>
                </Link>
              )}
              {loginState ? (
                <Link
                  to="/profile"
                  className={`text-base  hover:text-black transition-all duration-200 ${
                    loactionPath("/") && "text-blue-500"
                  }`}
                >
                  <li>My Profile</li>
                </Link>
              ) : (
                <Link
                  to="/login"
                  className={`bg-[#605DEC] py-2 px-4 md:py-3 md:px-5 rounded-[5px] border-2 border-[#605DEC] text-base text-[#FAFAFA] hover:text-[#605DEC] hover:bg-white hover:border-2 hover:border-[#605DEC] transition-all duration-200 ${
                    loactionPath("/login") && "text-[#605DEC]"
                  }`}
                >
                  <li>Login</li>
                </Link>
              )}

              {loginState && (
                <Link
                  to=""
                  onClick={Logout}
                  className={`text-base  hover:text-black transition-all duration-200 ${
                    loactionPath("/") && "text-blue-500"
                  }`}
                >
                  <li>Logout</li>
                </Link>
              )}

              <Link
                to="/"
                className={`text-base  hover:text-black transition-all duration-200 ${
                  loactionPath("/") && "text-blue-500"
                }`}
              >
                <li>Customer Care:0735146633</li>
              </Link>
              <Link
                onClick={() => handleShare()}
                className={`text-base  hover:text-[#605DEC] transition-all duration-200 ${
                  loactionPath("/") && "text-blue-500"
                }`}
              >
                <li>Share App</li>
              </Link>
            </ul>
          )}
        </div>

        {/* Desktop View */}

        <div className="hidden md:flex items-center space-x-8">
          <ul className="hidden md:flex items-center space-x-8 text-white">
            <Link
              to="/"
              className={`text-base  hover:text-white transition-all duration-200 ${
                loactionPath("/") && "text-white"
              }`}
            >
              <li>Trips</li>
            </Link>
            {loginState && (
              <Link
                to="/bookings"
                className={`text-base  hover:text-white transition-all duration-200 ${
                  loactionPath("/") && "text-white"
                }`}
              >
                <li>My Bookings</li>
              </Link>
            )}
            {loginState ? (
              <Link
                to="/profile"
                className={`text-base  hover:text-white transition-all duration-200 ${
                  loactionPath("/") && "text-white"
                }`}
              >
                <li>My Profile</li>
              </Link>
            ) : (
              <Link
                to="/login"
                className={`bg-[#605DEC] py-2 px-4 md:py-3 md:px-5 rounded-[5px] border-2 border-[#605DEC] text-base text-[#FAFAFA] hover:text-[#605DEC] hover:bg-white hover:border-2 hover:border-[#605DEC] transition-all duration-200 ${
                  loactionPath("/login") && "text-[#605DEC]"
                }`}
              >
                <li>Login</li>
              </Link>
            )}
            {loginState && (
                <Link
                  to=""
                  onClick={Logout}
                  className={`text-white hover:text-black transition-all duration-200 ${
                    loactionPath("/") && "text-blue-500"
                  }`}
                >
                  <li>Logout</li>
                </Link>
              )}

            {/* <Link
              to="/hotels"
              className={`text-base hover:text-[#605DEC] transition-all duration-200 ${loactionPath("/hotels") && "text-[#605DEC]"}`}
            >
              <li>Places</li>
            </Link>
            <Link
              to="/packages"
              className={`text-base hover:text-[#605DEC] transition-all duration-200 ${loactionPath("/packages") && "text-[#605DEC]"}`}
            >
              <li>Packages</li>
            </Link> */}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
