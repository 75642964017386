import React, { useEffect, useState } from "react";
import FlightChoose from "../components/FlightChoose";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import { useAuth } from "../context/AuthContext";
import MyBookings from "../components/Bookings";

const Bookings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { search, comingTo, headingTo, date } = location.state || {};
  const userid = localStorage.getItem("quid");

  return (
    <div className="flex-1 w-full overflow-hidden bg-gray-200">
      
        <div>
          <Navbar />
          <div className="flex sm:px-6 px-6 justify-center items-center">
            <div className="w-full xl:max-w-[1280px]">
              <MyBookings userid={userid}
              />
            </div>
          </div>
          <div className="flex sm:px-6 px-6 justify-center items-center bg-blue-800">
            <div className="w-full xl:max-w-[1280px]">
              <Footer />
            </div>
          </div>
        </div>
    </div>
  );
};

export default Bookings;
