export const navLinks = [
  {
    id: "home",
    name: "Home",
    path: "/",
  },
  {
    id: "about",
    name: "About",
    path: "/about",
  },
  {
    id: "services",
    name: "Services",
    path: "/services",
  },
];

export const headingto = [
  {
    id: 1,
    name: "Nairobi",
  },
  {
    id: 2,
    name: "Nakuru",
  },
  {
    id: 3,
    name: "Mombasa",
  },
  {
    id: 4,
    name: "Kisii",
  },
  {
    id: 5,
    name: "Kisumu",
  },
  {
    id: 6,
    name: "Eldoret",
  },
];
export const comingfrom = [
  {
    id: 1,
    name: "Nairobi",
  },
  {
    id: 2,
    name: "Nakuru",
  },
  {
    id: 3,
    name: "Mombasa",
  },
  {
    id: 4,
    name: "Kisii",
  },
  {
    id: 5,
    name: "Kisumu",
  },
  {
    id: 6,
    name: "Eldoret",
  },
];

export const testimonials = [
  {
    id: 1,
    content:
      "Seamless Process from the time I logged into the app, made payment, took my seat till I got to my destination.",
    user: "Julia Kemuma",
  },
  {
    id: 2,
    content:
      "They mean business.I got value for my money.They do deserve a tip.",
    user: "Farida Adnan",
  },
  {
    id: 3,
    content:
      "Driver kept time and was keen on the road.",
    user: "Darius Kiptoo",
  },
];
