import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

const PasswordresetForm = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const verifyEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "https://lipabooking.vercel.app/api/v1/user/forgetPassword",
        { email }
      );
      console.log(response.data);
      toast.success("Email sent successfully.Click on link to reset password");
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      toast.error("Failed to verify email");
      setLoading(false);
    }
  };
  return (
    <div className="h-screen justify-center items-center flex">
      <div class="w-80 rounded-lg shadow h-auto p-6 bg-white relative overflow-hidden">
        <div class="flex flex-col justify-center items-center space-y-2">
          <h2 class="text-2xl font-medium text-slate-700">Password reset</h2>
          <p class="text-slate-500">Enter details below.</p>
        </div>
        <form class="w-full mt-4 space-y-3" onSubmit={verifyEmail}>
          <div>
            <input
              class="outline-none border-2 rounded-md px-2 py-1 text-slate-500 w-full focus:border-blue-300"
              placeholder="enter your email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              type="email"
              required
            />
          </div>

          {!loading && (
            <button
              class="w-full justify-center py-1 bg-blue-500 hover:bg-blue-600 active:bg-blue-700 rounded-md text-white ring-2"
              id="login"
              name="login"
              type="submit"
            >
              Send Reset Email
            </button>
          )}
          <p class="flex justify-center space-x-1">
            <span class="text-slate-700"> Remembered your password? </span>
            <a class="text-blue-500 hover:underline" href="/login">
              Login
            </a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default PasswordresetForm;
