import { useEffect, useState } from "react";
import { map, xeddo } from "../assets/images";
import { hawaiian } from "../assets/logo";
import { FlightCard, PriceDetails, PriceGraph } from "../container";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import TripLoader from "./TripLoader";
import BookingCard from "./BookingCard";
import { Dialog, DialogContent, Button } from "@mui/material";
import { jsPDF } from "jspdf";

const MyBookings = ({ comingTo, headingTo, date, userid }) => {
  const [priceShown, setPriceShow] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [bookdetailsshow, setBookdetailsShow] = useState(false);

  console.log(userid);

  const userEmail = localStorage.getItem("email");
  const [userdata, setUserdata] = useState({});
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    getUserdata();
  }, []);

  const getUserdata = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://lipabooking.vercel.app/api/v1/user/user/${userEmail}`
      );

      const user = response.data[0];
      if (user) {
        setUserdata(response.data[0]);
        setUsername(user.username);
        setEmail(user.email);
        setAddress(user.address);
        setPhone(user.phone.toString());
        setLoading(false);
      } else {
        alert("error getting user details");
      }
    } catch (error) {
      console.log(error);
      alert("Failed to fetch user data");
    }
  };

  const formatDate = (date) => {
    if (!date) return "";
    return new Date(date).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const formattedDate = formatDate(date);

  const getTrips = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://lipabooking.vercel.app/api/v1/user/userbookings/${userid}`
      );
      const data = response.data;
      console.log(data);
      if (data) {
        setLoading(false);
        setTrips(data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTrips();
  }, []);

  const showDetails = (trip) => {
    setSelectedTrip(trip);
    setBookdetailsShow(true);
  };

  const hideBookDetails = () => {
    setBookdetailsShow(false);
    setSelectedTrip(null);
  };

  const downloadReceipt = () => {
    const doc = new jsPDF();

    // Adding the logo
    const imgData = xeddo; // The logo image you want to use
    doc.addImage(imgData, "PNG", 10, 10, 50, 20);

    // Adding the receipt details
    doc.setFontSize(12);
    doc.text("Receipt", 90, 40);
    doc.text(`Name: ${username}`, 10, 50);
    doc.text(`Email: ${email}`, 10, 60);
    doc.text(`Phone: ${phone}`, 10, 70);
    doc.text(`From: ${selectedTrip.from}`, 10, 80);
    doc.text(`To: ${selectedTrip.to}`, 10, 90);
    doc.text(`Leaves At: ${selectedTrip.leavesAt}`, 10, 100);
    doc.text(`Vehicle Name: ${selectedTrip.vehiclename}`, 10, 110);
    doc.text(`Vehicle Reg: ${selectedTrip.vehiclereg}`, 10, 120);
    doc.text(`Trip Date: ${selectedTrip.tripdate}`, 10, 130);
    doc.text(`Selected Seats: ${selectedTrip.seats.join(",")}`, 10, 140);

    // Saving the PDF
    doc.save("receipt.pdf");
  };

  return (
    <>
      <Dialog open={bookdetailsshow} onClose={hideBookDetails} className="">
        <DialogContent className="bg-white h-full w-full rounded-2xl justify-center items-center">
          {selectedTrip && (
            <div className="w-full rounded bg-gray-50 px-6 pt-8 shadow-lg">
              <img src={xeddo} alt="chippz" className="mx-auto w-full py-4" />
              <div className="flex flex-col justify-center items-center gap-2">
                <h4 className="font-semibold">Xeddo Travelink</h4>
                <p className="text-xs">Some address goes here</p>
              </div>
              <div className="flex flex-col gap-3 border-b py-6 text-xs">
                <p className="flex justify-between">
                  <span className="text-gray-400">Receipt No.:</span>
                  <span>{selectedTrip._id}</span>
                </p>
                <p className="flex justify-between">
                  <span className="text-gray-400">Name.:</span>
                  <span>{username}</span>
                </p>
                <p className="flex justify-between">
                  <span className="text-gray-400">From.:</span>
                  <span>{selectedTrip.from}</span>
                </p>
                <p className="flex justify-between">
                  <span className="text-gray-400">To:</span>
                  <span>{selectedTrip.to}</span>
                </p>
                <p className="flex justify-between">
                  <span className="text-gray-400">Leaves At:</span>
                  <span>{selectedTrip.leavesAt}</span>
                </p>
                <p className="flex justify-between">
                  <span className="text-gray-400">Vehicle Name:</span>
                  <span>{selectedTrip.vehiclename}</span>
                </p>
                <p className="flex justify-between">
                  <span className="text-gray-400">Vehicle Reg:</span>
                  <span>{selectedTrip.vehiclereg}</span>
                </p>
                <p className="flex justify-between">
                  <span className="text-gray-400">Trip Date:</span>
                  <span>{selectedTrip.tripdate}</span>
                </p>
                <p className="flex justify-between">
                  <span className="text-gray-400">Selected Seats:</span>
                  <span>{selectedTrip.seats.join(",")}</span>
                </p>
              </div>
              <div className="flex flex-col gap-3 pb-6 pt-2 text-xs">
                <div className=" border-b border border-dashed"></div>
                <div className="py-4 justify-center items-center flex flex-col gap-2">
                  <p className="flex gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M21.3 12.23h-3.48c-.98 0-1.85.54-2.29 1.42l-.84 1.66c-.2.4-.6.65-1.04.65h-3.28c-.31 0-.75-.07-1.04-.65l-.84-1.65a2.567 2.567 0 0 0-2.29-1.42H2.7c-.39 0-.7.31-.7.7v3.26C2 19.83 4.18 22 7.82 22h8.38c3.43 0 5.54-1.88 5.8-5.22v-3.85c0-.38-.31-.7-.7-.7ZM12.75 2c0-.41-.34-.75-.75-.75s-.75.34-.75.75v2h1.5V2Z"
                        fill="#000"
                      ></path>
                      <path
                        d="M22 9.81v1.04a2.06 2.06 0 0 0-.7-.12h-3.48c-1.55 0-2.94.86-3.63 2.24l-.75 1.48h-2.86l-.75-1.47a4.026 4.026 0 0 0-3.63-2.25H2.7c-.24 0-.48.04-.7.12V9.81C2 6.17 4.17 4 7.81 4h3.44v3.19l-.72-.72a.754.754 0 0 0-1.06 0c-.29.29-.29.77 0 1.06l2 2c.01.01.02.01.02.02a.753.753 0 0 0 .51.2c.1 0 .19-.02.28-.06.09-.03.18-.09.25-.16l2-2c.29-.29.29-.77 0-1.06a.754.754 0 0 0-1.06 0l-.72.72V4h3.44C19.83 4 22 6.17 22 9.81Z"
                        fill="#000"
                      ></path>
                    </svg>{" "}
                    {email}
                  </p>
                  <p className="flex gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill="#000"
                        d="M11.05 14.95L9.2 16.8c-.39.39-1.01.39-1.41.01-.11-.11-.22-.21-.33-.32a28.414 28.414 0 01-2.79-3.27c-.82-1.14-1.48-2.28-1.96-3.41C2.24 8.67 2 7.58 2 6.54c0-.68.12-1.33.36-1.93.24-.61.62-1.17 1.15-1.67C4.15 2.31 4.85 2 5.59 2c.28 0 .56.06.81.18.26.12.49.3.67.56l2.32 3.27c.18.25.31.48.4.7.09.21.14.42.14.61 0 .24-.07.48-.21.71-.13.23-.32.47-.56.71l-.76.79c-.11.11-.16.24-.16.4 0 .08.01.15.03.23.03.08.06.14.08.2.18.33.49.76.93 1.28.45.52.93 1.05 1.45 1.58.1.1.21.2.31.3.4.39.41 1.03.01 1.43zM21.97 18.33a2.54 2.54 0 01-.25 1.09c-.17.36-.39.7-.68 1.02-.49.54-1.03.93-1.64 1.18-.01 0-.02.01-.03.01-.59.24-1.23.37-1.92.37-1.02 0-2.11-.24-3.26-.73s-2.3-1.15-3.44-1.98c-.39-.29-.78-.58-1.15-.89l3.27-3.27c.28.21.53.37.74.48.05.02.11.05.18.08.08.03.16.04.25.04.17 0 .3-.06.41-.17l.76-.75c.25-.25.49-.44.72-.56.23-.14.46-.21.71-.21.19 0 .39.04.61.13.22.09.45.22.7.39l3.31 2.35c.26.18.44.39.55.64.1.25.16.5.16.78z"
                      ></path>
                    </svg>{" "}
                    {phone}
                  </p>
                </div>
                <Button onClick={downloadReceipt} variant="contained" color="primary">
                  Download Receipt
                </Button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <div className="flex lg:flex-row flex-col items-start justify-between gap-16 ">
        <div className="w-full lg:w-[872px] h-full flex flex-col gap-5">
          <div className="flex items-start justify-start">
            <h1 className="text-[#6E7491] text-lg leading-6 font-semibold">
              <span className="text-[#605DEC]"> </span>{" "}
              <span className="text-[#605DEC]"> </span>
            </h1>
          </div>
          <div className="flex items-center justify-between w-full">
            <div>
              <p className="text-black font-semibold text-2xl tracking-wider">
                {comingTo}
              </p>
            </div>
            <div>
              <img
                src="../../images/forward.png"
                className="w-8 h-8 object-fit"
                alt=""
              />
            </div>
            <div>
              <p className="font-semibold text-black text-2xl tracking-wider">
                {headingTo}
              </p>
            </div>
          </div>
          <div>
            {loading ? (
              <div>
                <TripLoader />
              </div>
            ) : (
              <div>
                {trips.map((trip) => {
                  return (
                    <div className="w-full flex flex-col items-start justify-start border-[1px] border-[#E9E8FC] rounded-xl">
                      <div
                        className="w-full cursor-pointer border-b-[1px] border-[#E9E8FC] hover:bg-[#F6F6FE] transition-all duration-300 focus:bg-[#F6F6FE]"
                      >
                        <BookingCard
                          img={hawaiian}
                          from={trip.from}
                          name={headingTo}
                          time={trip.leavesAt}
                          to={trip.to}
                          leaves={trip.leavesAt}
                          arrives={trip.arrivalTime}
                          hnl="2h 45m in HNL"
                          vehiclename={trip.vehiclename}
                          vehiclereg={trip.vehiclereg}
                          trip="round trip"
                          showDetails={() => showDetails(trip)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="w-full lg:mt-12">
            <img src={xeddo} alt="map" className="w-full h-full object-cover" />
          </div>
        </div>

        {priceShown && <PriceGraph />}

        {!priceShown && (
          <div className="mt-10 flex flex-col gap-10 justify-end items-start lg:items-end">
            <PriceDetails />
            <Link to="/passenger-info" className="mt-5">
              <button className="text-[#605DEC] border-2 border-[#605DEC] py-2 px-3 rounded hover:bg-[#605DEC] hover:text-white transition-all duration-200">
                Save & Close
              </button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default MyBookings;
