import React, { useEffect, useState } from "react";
import { xeddo } from "../assets/images";
import axios from "axios";
import { toast } from "react-toastify";
import UpdateLoader from "./UpdateLoader";

const Profile = () => {
  const userEmail = localStorage.getItem("email");
  const [userdata, setUserdata] = useState({});
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserdata();
  }, []);

  // const getUserdata = () => {
  //   setLoading(true);
  //   fetch(`https://e2d1-197-232-60-144.ngrok-free.app/api/v1/user/user/${userEmail}`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const user = data.find((item) => item.email === userEmail);
  //       if (user) {
  //         setUserdata(user);
  //         setUsername(user.username);
  //         setEmail(user.email);
  //         setAddress(user.address);
  //         setPhone(user.phoneNumber);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getUserdata = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://lipabooking.vercel.app/api/v1/user/user/${userEmail}`
      );

      const user = response.data[0];
      if (user) {
        setUserdata(response.data[0]);
        setUsername(user.username);
        setEmail(user.email);
        setAddress(user.address);
        setPhone(user.phone.toString());
        setLoading(false);
      } else {
        alert("error getting user details");
      }
    } catch (error) {
      console.log(error);
      alert("Failed to fetch user data");
    }
  };

  const updateUser = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await axios.put(
        `https://lipabooking.vercel.app/api/v1/user/updateuser/${userEmail}`,
        {
          username: username,
          email: email,
          phone: phone,
          address: address,
        }
      );
      setUserdata(response.data);
      toast.success("User updated successfully");
      setLoading(false);

      // Check if email was updated, and log out if true
      if (response.data.email !== userEmail) {
        toast.warning("Logging you out now!!");
        // Navigate to the logout screen or perform logout-related action
        handleLogout(); // Replace 'LogoutScreen' with your actual logout screen
      }
    } catch (error) {
      console.log(error);
      alert("Failed");
      toast.success("Error occurred while updating");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("Authenticated");
    window.location.reload();
  };
  return (
    <div>
      {loading ? (
        <div className="h-screen w-full justify-center items-center">
          <UpdateLoader />
        </div>
      ) : (
        <div>
          <div class="mx-4 min-h-screen max-w-screen-xl sm:mx-8 xl:mx-auto">
            <h1 class="border-b py-6 text-4xl font-semibold">Settings</h1>
            <div class="grid grid-cols-8 pt-3 sm:grid-cols-10">
              <div class="relative my-4 w-56 sm:hidden">
                <input
                  class="peer hidden"
                  type="checkbox"
                  name="select-1"
                  id="select-1"
                />
                <label
                  for="select-1"
                  class="flex w-full cursor-pointer select-none rounded-lg border p-2 px-3 text-sm text-gray-700 ring-blue-700 peer-checked:ring"
                >
                  Accounts{" "}
                </label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="pointer-events-none absolute right-0 top-3 ml-auto mr-5 h-4 text-slate-700 transition peer-checked:rotate-180"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
                <ul class="max-h-0 select-none flex-col overflow-hidden rounded-b-lg shadow-md transition-all duration-300 peer-checked:max-h-56 peer-checked:py-3">
                  <li class="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white">
                    Accounts
                  </li>
                  <li class="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white">
                    Team
                  </li>
                  <li class="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white">
                    Others
                  </li>
                </ul>
              </div>

              <div class="col-span-2 hidden sm:block">
                <ul>
                  <li class="mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700">
                    Bookings
                  </li>
                  <li class="mt-5 cursor-pointer border-l-2 border-l-blue-700 px-2 py-2 font-semibold text-blue-700 transition hover:border-l-blue-700 hover:text-blue-700">
                    Accounts
                  </li>
                </ul>
              </div>

              <div class="col-span-8 overflow-hidden rounded-xl sm:bg-gray-50 sm:px-8 sm:shadow">
                <div class="pt-4">
                  <h1 class="py-2 text-2xl font-semibold">Account settings</h1>
                </div>
                <hr class="mt-4 mb-8" />
                <p class="py-2 text-xl font-semibold">Email Address</p>
                <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                  <p class="text-gray-600">
                    Your email address is <strong>{email}</strong>
                  </p>
                  
                </div>

                {/* userdetails
                 */}
                 <form action="" onSubmit={updateUser}>
                <div class="flex items-center">
                  <div class="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-3">
                    
                    <label for="login-password">
                      <span class="text-sm text-gray-500">Username</span>
                      <div class="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
                        <input
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          type="text"
                          id="login-password"
                          class="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                          placeholder="***********"
                        />
                      </div>
                    </label>
                    <label for="login-password">
                      <span class="text-sm text-gray-500">Phone</span>
                      <div class="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
                        <input
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          type="text"
                          id="login-password"
                          class="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                          placeholder="***********"
                        />
                      </div>
                    </label>
                  </div>
                </div>
                <div class="flex items-center">
                  <div class="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-3">
                    <label for="login-password">
                      <span class="text-sm text-gray-500">Address</span>
                      <div class="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
                        <input
                         value={address}
                         onChange={(e) => setAddress(e.target.value)}
                          type="text"
                          id="login-password"
                          class="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                          placeholder="***********"
                        />
                      </div>
                    </label>
                    <label for="login-password">
                      <span class="text-sm text-gray-500">Email</span>
                      <div class="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="text"
                          id="login-password"
                          class="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                          placeholder="***********"
                        />
                      </div>
                    </label>

                    
                  </div>
                  
                </div>
                <button className="w-80 rounded-md p-2 bg-blue-500 text-white">Update</button>
                </form>
                <hr class="mt-4 mb-8" />

{/*                 
                <p class="py-2 text-xl font-semibold">Password</p>
                <div class="flex items-center">
                  <div class="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-3">
                    <label for="login-password">
                      <span class="text-sm text-gray-500">
                        Current Password
                      </span>
                      <div class="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
                        <input
                          type="password"
                          id="login-password"
                          class="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                          placeholder="***********"
                        />
                      </div>
                    </label>
                    <label for="login-password">
                      <span class="text-sm text-gray-500">New Password</span>
                      <div class="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
                        <input
                          type="password"
                          id="login-password"
                          class="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                          placeholder="***********"
                        />
                      </div>
                    </label>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mt-5 ml-2 h-6 w-6 cursor-pointer text-sm font-semibold text-gray-600 underline decoration-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                    />
                  </svg>
                </div>

                <p class="mt-2">
                  Can't remember your current password.{" "}
                  <a
                    class="text-sm font-semibold text-blue-600 underline decoration-2"
                    href="#"
                  >
                    Recover Account
                  </a>
                </p>
                <button class="mt-4 rounded-lg bg-blue-600 px-4 py-2 text-white">
                  Save Password
                </button>
                <hr class="mt-4 mb-8" /> */}

                
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
