import {  useNavigate } from "react-router-dom";
import { home, msunrise, shangai, sunrise, sydney, temple } from "../assets/images";
import FlightDealsCard from "../container/FlightDealsCard";

const FlightDeals = () => {

  const navigate = useNavigate()

  const handleSeeAllClick = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    navigate('/packages')
    
  };

  return (
    <>
      <div className="px-8 flex flex-col gap-7">
        <div className="flex items-center justify-between">
          
          <div
            className="flex items-start justify-center gap-1 cursor-pointer"
            onClick={handleSeeAllClick}
          >
            {/* <p className="text-[#A1B0CC] text-sm md:text-lg" >All</p> */}
            
          </div>
        </div>
        <div className="flex md:gap-8 flex-wrap items-center justify-center ">
          <FlightDealsCard
            image={home}
            title="The Bund, "
            name="Shanghai"
            price="$598"
            des=" China’s most international city"
          />
          
        </div>

        {/* <div className="w-full h-full flex flex-col dealsShadow rounded-b gap-2 ">
           <div className="w-full h-full">
             <img src={sunrise} alt="" className="w-full h-full object-cover rounded-t hidden md:block" />
             <img src={msunrise} alt="" className="w-full h-full object-cover rounded-t block md:hidden" />
           </div>
           <div className="w-full h-full flex flex-col justify-center items-start gap-1 px-4 py-3">
          <div className="flex flex-row items-center justify-between w-full">
            <h1 className="text-[#6E7491] text-base font-medium capitalize">
            Tsavo East National Park, <span className="text-[#605DEC]">Kenya</span>
            </h1>
            <p className="text-[#6E7491] text-base font-medium">$1,248</p>
          </div>
          <p className="text-[#7C8DB0] text-sm font-normal">
          Named after the Tsavo River, and opened in April 1984, Tsavo East National Park is one of the oldest parks in Kenya. It is located in the semi-arid Taru Desert.
          </p>
        </div>
        </div> */}

      </div>
    </>
  );
};

export default FlightDeals;
