import { Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import BookingPage from "./pages/BookingPage";
import ExplorePage from "./pages/ExplorePage";
import BookPage from "./pages/BookPage";
import PrivateRoute from "./context/PrivateRoute";
import ProfilePage from "./pages/ProfilePage";
import Bookings from "./pages/Bookings";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FiveSeater from "./pages/FiveSeater";
import SevenSeater from "./pages/SevenSeater";
import RegisterPage from "./pages/RegisterPage";
import ElevenSeater from "./pages/ElevenSeater";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";

function App() {
  return (
    <div>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* Private routes */}
        <Route element={<PrivateRoute />}>
          <Route path="/booking" element={<BookingPage />} />
          <Route path="/explore" element={<ExplorePage />} />
          <Route path="/book" element={<BookPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/bookings" element={<Bookings />} />
          <Route path="/five-seater" element={<FiveSeater />} />
          <Route path="/eleven-seater" element={<ElevenSeater />} />
          <Route path="/seven-seater" element={<SevenSeater />} />
        </Route>
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
