import {
  appStore,
  facebook,
  googlePlay,
  instagram,
  twitter,
} from "../assets/icons";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import Testmonals from "./Testmonals";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Footer = () => {
  const [open, setOpen] = useState(false);
  const [openabout, setOpenAbout] = useState(false);
  const [opentestimonials, setOpentestimonials] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpenAbout = () => setOpenAbout(true);
  const handleOpentestimonials = () => setOpentestimonials(true);
  const handleClose = () => setOpen(false);
  const handleCloseAbout = () => setOpenAbout(false);
  const handleCloseTest = () => setOpentestimonials(false);
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check out this app!",
          text: "I found this amazing app. You should try it too!",
          url: window.location.href, // Current page URL
        });
        console.log("Content shared successfully");
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };
  return (
    <div className="">
      <Modal
        className="w-full justify-center items-center flex"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Testmonals/> */}

        <Box className="bg-white md:w-[70%] w-full overflow-y-scroll h-full p-16 rounded-md">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="font-semibold"
          >
            Terms & Conditions
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            className="text-slate-900"
          >
            Purchasing through this app means you are aware of and accept our
            terms and conditions. Xeddo Travelink is a company in the transport
            industry in East Africa focusing on connecting travelers and
            cabs/drivers. Simply put, in normal circumstances, you will go to
            the bus stage and pick a bus; here, we simplify things such that you
            can see available buses/cars and seats online and then book in
            advance. Therefore, other conditions remain the same as in
            traditional traveling methods; for example, you are responsible for
            your luggage and safety. Nonetheless, we try our best to close gaps
            in the travel industry. For instance, we ensure drivers who partner
            with us meet expected regulations such as having up-to-date car
            insurance, a driver’s license, and an up-to-date certificate of good
            conduct. Drivers must submit these documents to us before we
            contract with them. Passengers have to provide their registered
            mobile number. All these requirements are intended to enhance
            safety. Another safety feature is that drivers and passengers can
            share trip information with family and friends. Our purpose is to
            create traveling convenience; therefore, our cars leave the pick-up
            station at the appointed time. So travelers need to get to the
            pick-up station at least 20 minutes before the time of departure
            clocks. You can get a refund if you have booked but did not travel.
            However, you may be penalized 5% of the trip price for causing
            inconvenience. You are encouraged to consult with our 24/7 customer
            support (0735 14 66 33) through WhatsApp or normal call, to avoid
            inconveniences. In conclusion, any other expectations may be beyond
            our scope. Nonetheless, you need not be worried; we are a friendly
            team that is flexible and determined to put a smile on your face.
            You are encouraged to consult with our customer support, available
            24/7, in case of any issue. Our email is xeddotravelink@gmail.com.
            We are your expeditious, stellar, travelpal.
          </Typography>
          <div className="flex justify-end items-end px-5">
            <button
              onClick={handleClose}
              className="bg-blue-500 p-3 text-white rounded-md"
            >
              Ok
            </button>
          </div>
        </Box>
      </Modal>

      {/* testimonials */}
      <Modal
        className="w-full justify-center items-center flex"
        open={opentestimonials}
        onClose={handleCloseTest}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Testmonals/> */}

        <Box className="bg-white md:w-[70%] w-full overflow-y-scroll h-full p-16 rounded-md">
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            className="text-slate-900"
          >
            <Testmonals />
          </Typography>
          <div className="flex justify-end items-end px-5">
            <button
              onClick={handleCloseTest}
              className="bg-blue-500 p-3 text-white rounded-md"
            >
              Close
            </button>
          </div>
        </Box>
      </Modal>

      {/* about */}
      <Modal
        className="w-full justify-center items-center flex"
        open={openabout}
        onClose={handleCloseAbout}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <Testmonals/> */}

        <Box className="bg-white md:w-[70%] w-full overflow-y-scroll h-full p-16 rounded-md">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="font-semibold"
          >
            About Xeddo Travelink
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            className="text-slate-900"
          >
            Xeddo Travelink is a company in the transport industry in East
            Africa that focuses on connecting travelers and cabs/drivers. In
            traditional transport, you go to the bus station and book a seat
            physically; with Xeddo Travelink, you will see available vehicles
            and seats online and book in advance. Our purpose is to create
            traveling convenience. Once you have booked, you will receive a
            receipt containing trip details, including the driver’s phone
            number. Cars leave the pick-up station at the appointed time. We are
            a friendly team that is flexible and determined to put a smile on
            your face. If you have any issues, you are encouraged to consult
            with our 24/7 customer support (0735 14 66 33) through WhatsApp or a
            normal call. Our email is xeddotravelink@gmail.com. We are your
            expeditious, stellar travelpal.
          </Typography>
          <div className="flex justify-end items-end px-5">
            <button
              onClick={handleCloseAbout}
              className="bg-blue-500 p-3 text-white rounded-md"
            >
              Ok
            </button>
          </div>
        </Box>
      </Modal>

      <div className="mt-40 flex flex-col gap-5 px-8 text-white">
        <div className="flex justify-between items-start flex-col md:flex-row gap-7">
          <div className="flex justify-start items-start">
            <h1 className="text-white font-bold text-2xl">Xeddo Travelink</h1>
          </div>
          <ul className="flex flex-col items-start justify-start gap-3">
            <h2 className="text-white font-bold text-lg">
              <p className="text-white">
                {" "}
                About{" "}
              </p>
            </h2>
            <li onClick={handleOpenAbout} className="footerLi">
              <p className="text-white">
                {" "}
                About Xeddo Travelink
              </p>
            </li>
            <li onClick={handleOpen} className="footerLi">
              <p className="text-white">Terms & Conditions</p>
            </li>
          </ul>

          <ul className="flex flex-col items-start justify-start gap-3">
            <h2 className="text-white font-bold text-lg">Support</h2>

            <li className="footerLi">
              <a href="" className="text-white">
                {" "}
                Contact us:0735146633
              </a>
            </li>
            <li onClick={handleOpentestimonials} className="footerLi">
              <p className="text-white">
                {" "}
                Testimonials
              </p>
            </li>
          </ul>
          <ul className="flex flex-col items-start justify-start  gap-3">
            <h2 className="text-white font-bold text-lg">Get the app</h2>

            <li className="footerLi" onClick={() => handleShare()}>
              <p className="text-white">
                {" "}
                Share
              </p>
            </li>
            <li className="footerLi">
              <p className="text-white">
                {" "}
                Xeddo Travelink for Android
              </p>
            </li>
            <li className="footerLi">
              <p className="text-white">
                Xeddo Travelink for iOS
              </p>
            </li>
          </ul>
        </div>
        <div className="border-t-2 border-[#CBD4E6] py-8 flex justify-between items-center">
          <div className="flex items-center justify-center gap-3">
            <img
              src={twitter}
              alt="twitter"
              className="cursor-pointer object-cover w-5 h-5 sm:w-7 sm:h-7"
            />
            <img
              src={instagram}
              alt="twitter"
              className="cursor-pointer object-cover w-5 h-5 sm:w-7 sm:h-7"
            />
            <img
              src={facebook}
              alt="twitter"
              className="cursor-pointer text-white object-cover w-5 h-5 sm:w-7 sm:h-7"
            />
          </div>
          <p className="text-white text-sm sm:text-base">
            &copy; 2024 Xeddo Travelink
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
