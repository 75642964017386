import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import Navbar from "../components/Navbar";
import { PriceGraph } from "../container";
import { xeddo } from "../assets/images";
import Footer from "../components/Footer";
import { useAuth } from "../context/AuthContext";
import Loader from "../components/Loader";
import SeatLoader from "../components/SeatLoader";
import PaymentLoader from "../components/PaymentLoader";

const rows = [
  ["1A", "1B", "Driver"],
  ["2A", "2B", "2C"],
  ["3A", "3B", "3C"],
];

const BookPage = () => {
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [bookedSeats, setBookedSeats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [paybillVisible, setPaybillVisible] = useState(false);
  const location = useLocation();
  const userid = localStorage.getItem("quid");

  console.log("Current User", userid);

  const {
    vehicleid,
    vehiclename,
    vehiclereg,
    price,
    leavingTime,
    departure,
    destination,
    tripdate,
  } = location.state || {};

  useEffect(() => {
    getBookedSeats();
  }, []);

  const getBookedSeats = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://lipabooking.vercel.app/api/v1/user/bookedseats/${vehicleid}`
      );
      setBookedSeats(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching booked seats:", error);
      setLoading(false);
    }
  };

  const toggleSeat = (seat) => {
    if (!bookedSeats.includes(seat)) {
      setSelectedSeats((prevSelectedSeats) => {
        const isSelected = prevSelectedSeats.includes(seat);
        const updatedSeats = isSelected
          ? prevSelectedSeats.filter((s) => s !== seat)
          : [...prevSelectedSeats, seat];
        return updatedSeats;
      });
    }
  };

  const totalPrice = selectedSeats.length * price;
  const newPhone = "254" + phoneNumber.slice(-9);

  const handleSubmit = async () => {
    setLoading(true);
    const form = {
      MerchantCode: "86633",
      NetworkCode: "63902",
      PhoneNumber: newPhone,
      TransactionDesc: "Payment for booking",
      AccountReference: newPhone,
      Currency: "KES",
      Amount: totalPrice,
      userId: userid,
      seats: selectedSeats.join(","),
      vehicleId: vehicleid,
      vehiclename,
      vehiclereg,
      leavesAt: leavingTime,
      from: departure,
      to: destination,
      tripdate,
    };

    try {
      hideModal();
      const response = await axios.post(
        "https://lipabooking.vercel.app/api/v1/payment/requestpayment",
        form
      );
      const CheckoutRequestID = response.data.CheckoutRequestID;

      setLoading(false);
      setPaymentLoader(true);
      setTimeout(() => {
        handlePaymentStatus(CheckoutRequestID);
      }, 32000);
    } catch (error) {
      console.error("Error requesting payment:", error);
      setLoading(false);
      alert("Failed to request payment. Please try again.");
    }
  };

  const showLoader = () => {
    setPaymentLoader(true);
  };
  const hideLoader = () => {
    getBookedSeats();
    setPaymentLoader(false);
    alert(
      "Booking Failed: Took you too long to respond or you cancelled the payment."
    );
  };
  const showSuccess = () => {
    setSuccess(true);
    getBookedSeats();
  };

  const handlePaymentStatus = async (CheckoutRequestID) => {
    try {
      const response = await axios.post(
        `https://lipabooking.vercel.app/api/v1/payment/paymentstatus/${CheckoutRequestID}`
      );
      const status = response.data;

      if (status.length === 0) {
        hideLoader();
      } else {
        hideLoader();
        showSuccess();
      }
    } catch (error) {
      console.error(error);
      showLoader(false);
    }
  };

  const handleCall = () => {
    setPaybillVisible(false);
    const cphoneNumber = 254735146633;
    const phoneURL = `tel:${cphoneNumber}`;

    window.open(phoneURL);
  };

  const hideModal = () => setModalVisible(false);
  const hidePaybillDialog = () => setPaybillVisible(false);

  return (
    <div className="flex-1 w-full overflow-hidden bg-gray-200">
      <Navbar />

      <Dialog open={modalVisible} onClose={hideModal}>
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <p>
            Are you sure you want to complete the payment with {phoneNumber}?
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Done</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={paybillVisible} onClose={hidePaybillDialog}>
        <DialogContent>
          <div className="flex flex-col items-center space-y-2">
            <p>Paybill Number</p>
            <p className="text-3xl font-bold">756756</p>
            <p>Account Number</p>
            <p className="text-3xl font-bold">86633</p>
            <button
              onClick={handleCall}
              className="bg-blue-700 h-12 w-60 rounded-xl text-white"
            >
              Call for help
            </button>
          </div>
        </DialogContent>
      </Dialog>

      {loading ? (
        <div className="h-screen w-full justify-center items-center flex">
          <SeatLoader />
        </div>
      ) : (
        <div>
          <div className="">
            {success ? (
              <div className="flex flex-col items-center space-y-10">
                <p className="text-2xl font-semibold text-slate-800">
                  Booking made Successfully
                </p>
                <p className="text-lg font-normal text-slate-800">
                  Xeddo Travelink Wishes you happy travels.
                </p>
                <img
                  className="h-24 w-24 object-contain"
                  src="/images/checked.png"
                  alt="Success"
                />
                <Link
                  to="/bookings" className="bg-blue-700 h-12 w-60 rounded-xl text-white font-semibold text-xl">
                  Continue
                </Link>
              </div>
            ) : (
              <div>
                {paymentLoader ? (
                  <div className="h-screen w-full justify-center items-center flex">
                  <PaymentLoader/>
                </div>
                ) : (
                  <div className="w-full sm:px-16 px-6">
                    <div className="flex justify-between md:flex-row flex-col items-center md:space-x-10">
                      <div className="h-screen hidden md:block md:w-[30%]">
                        <PriceGraph />
                      </div>
                      <div className="bg-white rounded-md h-screen md:w-[50%] w-full">
                        <div className="flex flex-col items-center space-y-5">
                          <img src={xeddo} className="w-full" alt="" />
                          {rows.map((row, rowIndex) => (
                            <div key={rowIndex} className="flex space-x-5">
                              {row.map((seat, seatIndex) => {
                                const isDriverSeat =
                                  rowIndex === 0 && seatIndex === 2;
                                const isBookedSeat = bookedSeats.includes(seat);
                                return (
                                  <button
                                    key={seat}
                                    className={`h-16 w-16 py-2 rounded-md flex flex-col items-center justify-center ${
                                      isDriverSeat
                                        ? "bg-slate-500"
                                        : isBookedSeat
                                        ? "bg-blue-400"
                                        : selectedSeats.includes(seat)
                                        ? "bg-yellow-500"
                                        : "bg-gray-300"
                                    }`}
                                    onClick={() =>
                                      !isDriverSeat &&
                                      !isBookedSeat &&
                                      toggleSeat(seat)
                                    }
                                    disabled={isDriverSeat || isBookedSeat}
                                  >
                                    <img
                                      className="h-8 w-8"
                                      src={
                                        isDriverSeat
                                          ? "/images/empty.png"
                                          : isBookedSeat
                                          ? "/images/booked.png"
                                          : "/images/empty.png"
                                      }
                                      alt={seat}
                                    />
                                    <p className="text-md font-semibold">
                                      {seat}
                                    </p>
                                  </button>
                                );
                              })}
                            </div>
                          ))}

                          <div className="w-full justify-center py-5 space-y-4 flex flex-col items-center">
                            <p className="text-2xl text-slate-500">
                              Seat types
                            </p>
                            <div className="w-full px-5 flex justify-between items-center">
                              <div>
                                <div className="bg-yellow-500 h-12 w-12 rounded-md justify-center items-center flex">
                                <img
                                      className="h-8 w-8"
                                      src="/images/empty.png"
                                      
                                      alt=""
                                    />
                                </div>
                              </div>
                              <div>
                                <p className="text-slate-500">
                                  Currently selected
                                </p>
                              </div>
                            </div>
                            <div className="w-full px-5 flex justify-between items-center">
                              <div>
                                <div className="bg-blue-500 h-12 w-12 rounded-md flex justify-center items-center">
                                <img
                                      className="h-8 w-8"
                                      src="/images/booked.png"
                                      
                                      alt=""
                                    />
                                </div>
                              </div>
                              <div>
                                <p className="text-slate-500">Booked Seat</p>
                              </div>
                            </div>
                            <div className="w-full px-5 flex justify-between items-center">
                              <div>
                                <div className="bg-slate-300 h-12 w-12 rounded-md flex justify-center items-center">
                                <img
                                      className="h-8 w-8"
                                      src="/images/empty.png"
                                      
                                      alt=""
                                    />
                                </div>
                              </div>
                              <div>
                                <p className="text-slate-500">Empty Seat</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="h-screen md:w-[50%] md:mt-0 mt-4 w-full ">
                        <div className="w-full md:px-10 px-4">
                          <div>
                            <p className="text-xl font-bold text-black">
                              Vehicle Information
                            </p>
                            <div className="py-3">
                              <div className="flex justify-between py-3 items-center">
                                <p className="font-semibold text-slate-600">
                                  Vehicle Name:
                                </p>
                                <p>{vehiclename}</p>
                              </div>
                              <div className="flex justify-between py-3 items-center">
                                <p className="font-semibold text-slate-600">
                                  Vehicle Registration:
                                </p>
                                <p>{vehiclereg}</p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p className="text-xl font-bold text-black">
                              Trip Information
                            </p>
                            <div className="py-3">
                              <div className="flex justify-between py-3 items-center">
                                <p className="font-semibold text-slate-600">
                                  From:
                                </p>
                                <p>{departure}</p>
                              </div>
                              <div className="flex justify-between py-3 items-center">
                                <p className="font-semibold text-slate-600">
                                  To:
                                </p>
                                <p>{destination}</p>
                              </div>
                              <div className="flex justify-between py-3 items-center">
                                <p className="font-semibold text-slate-600">
                                  Leaves At:
                                </p>
                                <p>{leavingTime}</p>
                              </div>
                              <div className="flex justify-between py-3 items-center">
                                <p className="font-semibold text-slate-600">
                                  Trip Price:
                                </p>
                                <p>Ksh.{price}</p>
                              </div>
                              <div className="flex justify-between py-3 items-center">
                                <p className="font-semibold text-slate-600">
                                  Trip Date:
                                </p>
                                <p>{tripdate}</p>
                              </div>
                              <div className="flex justify-between py-3 items-center">
                                <p className="font-semibold text-slate-600">
                                  Total Price:
                                </p>
                                <p className="text-2xl font-bold">
                                  Ksh.{totalPrice}
                                </p>
                              </div>
                              <div className="flex justify-between py-3 items-center">
                                <p className="font-semibold text-slate-600">
                                  Selected Seats:
                                </p>
                                <p className="font-bold">
                                  {selectedSeats.join(",")}
                                </p>
                              </div>
                              <div className="flex justify-between py-3 items-center">
                                <input
                                  type="text"
                                  placeholder="Enter paying Phone number"
                                  className="border px-4 border-slate-300 h-10 w-full rounded-md"
                                  value={phoneNumber}
                                  onChange={(e) =>
                                    setPhoneNumber(e.target.value)
                                  }
                                />
                              </div>

                              <div className="w-full flex justify-center">
                                {selectedSeats.length > 0 &&
                                  phoneNumber.length >= 10 && (
                                    <button
                                      onClick={() => setModalVisible(true)}
                                      className="bg-blue-500 h-12 w-80 rounded-md text-white font-semibold"
                                    >
                                      {loading ? (
                                        <CircularProgress
                                          size={20}
                                          color="inherit"
                                        />
                                      ) : (
                                        "Proceed to Payment"
                                      )}
                                    </button>
                                  )}
                              </div>
                              <div className="w-full flex flex-col justify-center space-y-2 mt-4">
                                <p className="text-slate-500">
                                  Error completing payment?
                                </p>
                                {selectedSeats.length > 0 && (
                                  <button
                                    onClick={() => setPaybillVisible(true)}
                                    className="bg-black h-12 w-full rounded-md text-white font-semibold"
                                  >
                                    Pay Manually
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {/* footer */}
      <Footer />
    </div>
  );
};

export default BookPage;
