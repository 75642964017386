import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import Reset from "../components/Reset";

const ResetPassword = () => {
    // const token = useParams()
    const newtoken = window.location.pathname.split("/").pop();
    // const newtoken = token.token;
    // console.log("token",token.token)
  return (
    <div className="flex-1 w-full overflow-hidden bg-gray-200">
      
      <div className="flex sm:px-16 px-6 justify-center items-center bg-blue-800">
        <div className="w-full xl:max-w-[1280px]">
          <Navbar />
        </div>
      </div>

      {/* Your Homepage Content */}
      {/* <Hero /> */}

      {/* Your Homepage Content */}
      

      <div className="flex sm:px-6 px-6 justify-center items-center">
        <div className="w-full xl:max-w-[1280px]">
          {/* Your Homepage Content */}
          <Reset token={newtoken} />
        </div>
      </div>
        

      
      <div className="flex sm:px-6 px-0 justify-center items-center bg-blue-800">
        <div className="w-full xl:max-w-[1280px] bg-blue-800">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
